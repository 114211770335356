<template>
  <div>
    <div class="main">
      <form :name="chooseTab == 0 ? 'loginkh' : 'login'"  method="post" :action="chooseTab == 0 ? 'http://www.ntslog.com/NTSEBiz/login.aspx' : 'http://erp.ntslog.com/erp/NtsLogin.aspx'">
      <input type="hidden" :name="chooseTab == 0 ? 'dispatchkh' : 'dispatch'" value="login" />
      <fieldset>
        <div class="login">
          <div class="tabs">
            {{ $t("message.login") }}
            <span
            :class="chooseTab == 1 ? 'tab-active tab ' : 'tab '"
            @click="changeTab(1)"
          >{{ $t("message.msg192") }}</span>
          <span
            :class="chooseTab == 0 ? 'tab-active tab ' : 'tab '"
            @click="changeTab(0)"
            >{{ $t("message.msg19") }}</span>
          </div>
          <div class="login-input">
            <img src="../assets/icon_user.png" alt />
            <input
              name="uid"
              type="text"
              id="uid"
              :placeholder="$t('message.Username')"
              v-model="user"
            />
            <!-- <span> {{ $t("message.msg18") }}</span> -->
          </div>
          <div class="login-input">
            <img src="../assets/icon_password.png" alt />
            <input name="pwd" type="password" :placeholder="$t('message.Password')" v-model="pwd" />
            <!-- <span> {{ $t("message.msg17") }}</span> -->
          </div>
          <input v-if="chooseTab == 1" style="padding-left:0px;cursor:pointer" class="tab-btn"  :type="user && pwd ? 'submit' : ''" :value="$t('message.login')"/>
          <div v-else
            style="padding-left:0px;cursor:pointer"
            class="tab-btn"
            @click="login"
          >{{$t('message.login')}}</div>
		  <div class="forget-pwd">
             <a target="_blank" :href="CusWebRoot+'cus/User/ModifyPassword?lang=' + $i18n.locale">{{$t('message.msg45')}}?</a>
          </div>
        </div>
      </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  mounted() {},
  data() {
    return {
      chooseTab: 0,
      chooseWay: "",
      user: "",
      pwd: "",
      sections: [],
    };
  },
  mounted() {
    if (this.$route.query.type && this.$route.query.type == 2) {
      this.chooseTab = 1;
    }
    if (this.$route.query.name) {
      this.user = this.$route.query.name;
    }
    this.getSlide();
    this.getSections();
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == "zh") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "zh";
      }
    },
    changeTab(index) {
      this.chooseTab = index;
    },
    login() {
      // 登录API:  POST http://localhost.../Cus/User/Login/SignInApi
      // 数据: {UserId: "haijun.chen@ntslog.com", Password: "123456"}
      // 成功返回: {"Success":true,"UserName":"TESTING","Systems":[{"Name":"","Url":"http://localhost/CoreWeb/Cus/User/Home"}]}
      // 失败返回: {"Success":false}
      let self = this;

      if (this.user && this.pwd) {
        this.$axios
          .post(this.CusWebRoot + `Cus/User/Login/SignInApi`, {
            // this.$axios.jsonp(`/Cus/User/Login/SignInApi`, {
            UserId: this.user,
            Password: this.pwd,
            UserType:this.chooseTab == 0 ? 'admin' : 'user'
          })
          .then(function (res) {
            console.log(res.data); 
            if (res.data.success) {
              self.$Message.warning("登录成功.");
              self.$EventBus.$emit("doLogin", res.data);
              self.$router.push("/");
            } else {              
			  alert(res.data.Message);
			  if(res.data.Url!=null){
				window.open(res.data.Url);
			  }
            }
          });
      }
    },
    //幻灯片
    getSlide() {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/main?lang=${this.$i18n.locale}`)
        .then(function (res) {
          self.slides = res.data.data;
        });
    },
    //文章
    getSections() {
      let self = this;
      this.$axios
        .get(
          `/api/portal/articles?page=1&listRows=10&relation=categories&category_id=1&lang=${this.$i18n.locale}`
        )
        .then(function (res) {
          self.sections = res.data.data;
        });
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getSlide();
      this.getSections();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 756px;
}
.main > img {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.main-content {
  height: 550px;
  box-sizing: border-box;
  position: relative;
  padding-top: 150px;
  color: rgba(255, 255, 255, 1);
  .main-title {
    font-size: 42px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 59px;
  }
  .main-des {
    width: 432px;
    margin-top: 18px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 20px;
    margin-bottom: 64px;
  }
  .main-btn {
    width: 156px;
    height: 49px;
    line-height: 49px;
  }
}
.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 423px;
  width: 92%;

  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  padding: 40px 24px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 35, 60, 0.4);
  border-radius: 8px;
  .tabs {
    overflow: hidden;
    font-size: 30px;
    font-weight: 400;
    color: rgba(0, 35, 60, 1);
    line-height: 48px;
    margin-bottom: 30px;
    .tab {
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      margin-left: 32px;
      float: right;
      line-height: 25px;
      padding-top: 18px;
    }
    .tab.tab-active {
      color: rgba(67, 176, 212, 1);
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .tab-title {
    margin-bottom: 8px;
  }
  input {
    height: 60px;
    line-height: 60px;
    border: none;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.24);
    outline: none;
    padding-left: 40px;
    width: 100%;
    background: transparent;
  }
  .tab-btn {
    margin-top: 60px;
    height: 48px;
    background: rgba(67, 176, 212, 1);
    color: #fff;
    line-height: 48px;
    box-sizing: border-box;
    text-align: center;
    margin-top: 34px;
  }
  .ivu-radio-wrapper {
    margin-right: 24px;
    font-size: 18px;
    color: rgba(0, 35, 60, 1);
  }
  .ivu-radio-wrapper-checked {
    color: rgba(67, 176, 212, 1);
  }
}
.login-input {
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  span {
    position: absolute;
    top: 50%;
    color: rgba(0, 35, 60, 1);
    right: 0px;
    transform: translateY(-50%);
    text-decoration: underline;
    word-wrap: none;
    white-space: nowrap;
  }
}
.forget-pwd{
  text-align: right;
  margin-top: 12px;
}
</style>