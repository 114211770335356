<template>
  <div class="search">
    <div class="w1200">
      <div class="search-title">{{ $t("message.msg32") }}</div>
      <div class="search-num">
        {{ $t("message.msg33") }}
        <span>{{resultsLength}} </span>{{ $t("message.msg34") }}
        <span>{{keywords}}</span>
      </div>
      <div class="search-input">
        <Input search  v-model="searchValue">
          <Button @click="goSearch"
            type="info"
            slot="append"
            style="color:#fff;background-color: #2db7f5;border-color: #2db7f5;"
            icon="ios-search"
          >{{ $t("message.msg35") }}</Button>
        </Input>
      </div>
      <div class="search-lists">
        <div class="search-list" v-for="item in results" :key="item.id" @click="goNewsDetail(item.id)">
          <div class="search-list-tit">{{item.post_title}}</div>
          <div
            class="search-list-des"
          >{{item.post_keywords}}</div>
          <img src="../assets/icon_More.png" class="search-list-ray" />
        </div>
      </div>
      <div class="pages">
        <Page :total="resultsLength" :page-size="20"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  components: {},
  data() {
    return {
      searchValue: "",
      keywords: "",
      results:[],
      resultsLength:''
    };
  },
  mounted() {
    console.log(this.$route);
    this.searchValue = this.$route.query.keywords;
    this.keywords = this.$route.query.keywords;
    this.getSections(this.$route.query.keywords);
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == "zh") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "zh";
      }
    },
    changeTab(index) {
      this.chooseTab = index;
    },
    //文章
    getSections(keywords) {
      let self = this;
      this.$axios
        .get(
          `/api/portal/articles?page=1&listRows=100&relation=categories&lang=${this.$i18n.locale}&keyword=${encodeURI(keywords) }`
        )
        .then(function(res) {
          self.results = res.data.data;
          self.resultsLength = self.results.length || 0
          self.keywords = keywords;
          self.searchValue = keywords;
        });
    },
        //文章详情
    goNewsDetail(id){
      this.$router.push('/newsdetail?id=' + id)
    },
    //搜索
    goSearch() {
      console.log(123);
      this.$router.push("/search?keywords=" + this.searchValue);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.getSlide();
      this.getSections();
    },
    "$route.query.keywords"() {
      this.getSections(this.$route.query.keywords)
    }
  }
};
</script>
<style lang="scss" scoped>
.search-title {
  margin: 48px auto 8px auto;

  font-size: 44px;
  text-align: center;
  font-weight: 400;
  color: rgba(0, 35, 60, 1);
  line-height: 78px;
}
.search-num {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 35, 60, 0.6);
  line-height: 20px;
  span {
    font-weight: bold;
    padding: 0px 4px;
  }
}
.search-input {
  min-width: 300px;
  width: 50%;
  margin: 24px auto;
}
.search-list {
  padding: 24px 0px;
  border-bottom: 1px solid rgba(191, 200, 206, 1);
  .search-list-tit {
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 35, 60, 1);
    line-height: 33px;
    margin-bottom: 16px;
  }
  .search-list-des {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 35, 60, 0.48);
    line-height: 22px;
  }
  .search-list-ray {
    margin-top: 14px;
  }
}
.pages {
  margin: 17px auto;
  text-align: center;
}
@media (max-width: 768px) {
  .search-lists{
    padding: 0px 16px;
  }
}

</style>