<template>
  <div class="main-wrap">
    <img class="img-banner" :src="imgUrl + detail.image" />
    <div class="news-detail-wrap">
         <div class="news-detail-info">
         <div class="news-detail ">
            <div class="news-time w1200">请联系我们
          </div>
          <div class="news-title w1200">  我们乐于为您<br />
            提供定制化的解决方案</div>
         </div>
        

        <div class="form-content">
          <div class="form-title">请填写您的信息</div>
          <input type="text" v-model="name" placeholder="您的姓名" />
          <input
            type="text"
            v-model="company_name"
            placeholder="您的公司名称"
          />
          <input type="text" v-model="job" placeholder="您的行业" />
          <input type="phone" v-model="mobile" placeholder="您的手机号码" />
          <div
            class="submit-btn"
            v-if="name && company_name && job && mobile"
            @click="submit"
          >
            确认提交
          </div>

          <div v-else class="submit-btn submit-btn-disable">确认提交</div>
        </div>
      </div>
    </div>
     <div id="cates" class="sec-title w1200">时乘软件服务：</div>
    <div class="sec-cates w1200">
      <div style="margin-right:-24px">
      <div
        :class="['sec-cate', selectCate == item.category.id ? 'sec-cate-active' : '']"
        v-for="(item, index) in navs"
        :key="index"
        @click="changeCate(item.category.id)"
      >
        {{ item.name }}
      </div>
      </div>
    </div>
    <div class="sec-cates-phone w1200">
      <Dropdown @on-click="changeCate"
        style="display: block"
        trigger="click"
        transfer
        transfer-class-name="ddds"
      >
        <div class="sec-phone-cate">
          <span  v-for="item in navs" v-if="selectCate == item.category.id">{{item.name}}</span>
     
          <img src="../assets/icon_down.png" alt="" srcset="" />
        </div>
        <DropdownMenu slot="list" style="left: 16px; right: 16px; width: auto">
          <DropdownItem
            v-for="item in navs"
            :key="item.name"
            :name="item.category.id"
            >{{ item.name }}</DropdownItem
          >
        </DropdownMenu>
      </Dropdown>
    </div>
    <div class="sections w1200">
      <Row type="flex" justify="space-between">
        <Col
          :xs="24"
          :sm="11"
          :lg="7"
          v-for="(item, index) in sections"
          :key="index"
        >
          <div class="section-wrap" @click="goNewsDetail(item.id)">
            <div class="section">
              <div class="section-time">
                {{ item.categories && item.categories[0].name }}
              </div>
              <img class="section-img" :src="item.thumbnail" alt />

              <div class="section-title">{{ item.post_title }}</div>
              <div class="section-more">
                了解更多 <img src="../assets/right.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </Col>
        <Col :xs="24" :sm="11" :lg="7"> </Col>
        <Col :xs="24" :sm="11" :lg="7"> </Col>
      </Row>
    </div>
    <div class="contact-wrap w1200" @click="goNewsDetail(contact.url)">
      <div class="contact">
        <div class="contact-time">
          {{ contact.title }}
        </div>

        <div class="contact-title">{{ contact.content }}</div>
        <div class="contact-more">
          了解更多 <img src="../assets/right-w.png" alt="" srcset="" />
        </div>
      </div>
      <img class="contact-img" :src="imgUrl + contact.image" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  components: {},
  mounted() {},
  data() {
    return {
      isLoading: false,
      isTotal: false,
      sections: [],
      contact: {},
      selectCate: 0,
      navs: "",
      page: 1,
      job: "",
      name: "",
      company_name: "",
      mobile: "",
      detail: {},
    };
  },
  mounted() {
    this.getCates();
    this.getSwiper2();
    this.getNewsDetail(this.$route.query.id);
        this.$EventBus.$on("goNav", (id) => {
          this.navs.map(v=>{
            if(v.category.id == id){
              this.changeCate(id)
            }
          })
    });
  },
  methods: {
    changeCate(id) {
      this.selectCate = id;
      this.getSections(id);
    },
    //分类
    getCates() {
      let self = this;

      this.$axios

        .get(`/api/portal/navMenu/menu?lang=${this.$i18n.locale}&nav=middle`)
        .then(function (res) {
          self.navs = res.data.data;
          self.selectCate = parseInt(res.data.data[0].category.id);
          self.getSections(self.selectCate);
        });
    },
    submit() {
      let self = this;
      this.$axios
        .post(`/api/extra/apply/apply`, {
          job: self.job,
          name: self.name,
          company_name: self.company_name,
          mobile: self.mobile,
          item: self.$route.query.id,
        })
        .then(function (res) {
          self.$Message.success("提交成功.");
        });
    },
    //文章
    getSections(id) {
      let self = this;
      this.$axios
        .get(
          `/api/portal/lists/getCategoryPostLists?category_id=${id}&page=${this.page}&listRows=60&lang=${this.$i18n.locale}&relation=categories`
        )
        .then(function (res) {
          console.log(res);
          self.sections = res.data.data.list;
        });
    },
    //跳转文章详情
    goNewsDetail(id) {
      this.$router.push("/newsdetail?id=" + id + `?lang=${this.$i18n.locale}`);
    },
    //获取报价页面
    getNewsDetail(id) {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/other?nav=40&lang=${this.$i18n.locale}&id=24`)
        .then(function (res) {
          console.log(res);
          self.detail = res.data.data;
        });
    },
    //联系我们
    getSwiper2() {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/other?nav=40&lang=${this.$i18n.locale}&id=25`)
        .then(function (res) {
          self.contact = res.data.data;
        });
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getSections();
      this.getNewsDetail(this.$route.query.id);
    },
    "$route.query.id"() {
      this.getSections();
      this.getNewsDetail(this.$route.query.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.news-wrap {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  margin-bottom: 36px;
  .news-title {
    font-size: 36px;
    font-weight: 500;
    color: rgba(0, 35, 60, 1);
    line-height: 78px;
    margin-top: 90px;
    left: 50%;
    text-align: left;
    transform: translateX(-50%);
    position: absolute;
    z-index: 9;
  }
  .banner {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

.btn-more {
  margin-bottom: 36px;
}

  .news-detail-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .news-detail-info .news-detail::after {
content: '';
position: absolute;
left: 0px;
right: 0px;
display: block;
top: -12px;
bottom: 0px;
z-index: -1;
background: rgba(0, 0, 0, 0.4);
  }
.news-detail {
  position: relative;
  img {
    max-width: 100%;
  }
  .news-detail-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .news-title {
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 33px;
    margin-bottom: 16px;
    text-align: left;
  }
  .news-time {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    margin-bottom: 16px;
    text-align: left;
  }
}
.news-content {
  padding-top: 48px;
  box-sizing: border-box;
}

.sec-cates {
  overflow: hidden;
  display: block;
}
.sec-cates-phone {
  display: none;
  margin-bottom: 16px;
}
.sec-cate {
  float: left;
  margin-right: 24px;
  margin-bottom: 24px;
  line-height: 56px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #080404;
  width: 180px;
  text-align: center;
  height: 56px;
  background: #fff;
  cursor: pointer;
}
.sec-cate:last-child {
  margin-right: 0px;
}
.sec-cate-active {
  line-height: 56px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #080404;
  width: 180px;
  text-align: center;
  height: 56px;
  background: #f8ba05;
  box-shadow: 0px 5px 8px -2px rgba(227, 169, 0, 0.47);
}
.sec-phone-cate {
  padding: 0px 24px;
  width: 100%;
  box-sizing: border-box;
  line-height: 56px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}
.sec-phone-cate img {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}
.sec-title {
  padding: 24px 0px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #080404;
  line-height: 33px;
}
.section-wrap {
  overflow: hidden;
  padding: 24px;
  background: #fff;
  margin-bottom: 36px;
}
.section-wrap:hover {
  box-shadow: 0px 7px 24px -2px rgba(8, 4, 4, 0.12);
}
.sections {
  margin-bottom: -36px;
}
.section {
  box-sizing: border-box;
  // max-width: 376px;
  flex: 1 1 300px;

  background: #fff;
  .section-time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #080404;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .section-title {
    margin: 16px 0px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #080404;
    line-height: 30px;
  }
  .section-img {
    height: 210px;
    width: 100%;
    object-fit: cover;
  }
  .section-des {
    font-size: 16px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 16px;
    font-weight: 400;
    color: rgba(0, 35, 60, 0.48);
    line-height: 24px;
    margin-bottom: 4px;
    text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.section-wrap:hover .section-more {
  background: #f8ba05;
}
.section-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 156px;
  padding: 12px;
  height: 48px;
  background: #fff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  cursor: pointer;
}
.section-more img {
  width: 22px;
  height: 22px;
}
.ivu-select-dropdown {
  left: 16px;
  right: 16px;
  width: auto;
}
.contact-wrap {
  margin: 72px auto;
  display: flex;
  height: 400px;
  position: relative;
  .contact {
    padding: 64px 24px 87px 48px;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f8ba05;
    position: relative;
    z-index: 3;
    .contact-time {
      margin-bottom: 24px;

      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #080404;
      line-height: 25px;
    }
    .contact-title {
      margin-bottom: auto;

      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #080404;
      line-height: 45px;
    }
    .contact-more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 156px;
      padding: 12px;
      height: 48px;
      background: #080404;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
        flex: 0 0 22px;
      }
    }
  }

  img {
    width: 48%;
    object-fit: cover;
    height: 100%;
  }
}
@media (max-width: 768px) {
    .news-detail {
      .news-time{
        font-size: 14px;
      }
  .news-title {
    font-size: 22px;
  }
  }
  .contact-wrap {
    height: 248px;
    .contact {
      padding: 24px;
      background: rgba(248, 186, 5, 0.8);
      .contact-time {
        margin-bottom: 24px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #080404;
        line-height: 20px;
      }
      .contact-title {
        margin-bottom: auto;
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #080404;
        line-height: 24px;
      }
    }
    > img {
      position: absolute;
      top: 0px;
      left: 16px;
      right: 16px;
      bottom: 0px;
      width: calc(100% - 32px);
    }
  }
  .sec-cates {
    display: none;
  }
  .sec-title {
    padding-left: 16px;
  }
  .section-wrap:hover .section-more {
    background: #fff;
  }
  .sec-cates-phone {
    display: block;
  }
}
.files {
  padding: 16px;
  line-height: 32px;
  a {
    display: block;
    text-decoration: underline;
  }
}
.img-banner {
  width: 100%;
  height: 500px;
  object-fit: cover;
  vertical-align: middle;
}
.news-detail-info {
  margin-top: -118px;
  position: relative;
  z-index: 3;
}

@media (max-width: 768px) {
  .img-banner {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .main-wrap {
    margin-top: 60px;
  }
  .news-detail-info {
    height: 538px;
    margin-top: -70px;
  }
}
.form-content {
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 44px 0px;
  .form-title {
    margin-bottom: 16px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #080404;
    line-height: 28px;
  }
  input {
    display: block;
    line-height: 67px;
    height: 67px;
    padding-left: 24px;
    box-sizing: border-box;
    color: #000000;
    width: 100%;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: #ffffff;
    margin-bottom: 24px;
    border: none;
    outline: none;
    box-shadow: 0px 1px 0px 0px rgba(8, 4, 4, 0.2);
  }
  .submit-btn {
    width: 180px;
    height: 56px;
    background: #f8ba05;
    line-height: 56px;
    cursor: pointer;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .submit-btn-disable {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(8, 4, 4, 0.12);
  }
}
@media (max-width: 768px) {
.form-content {
  input {
    font-size: 15px;
    height: 48px;
    line-height: 48px;
  }
  .submit-btn {
    width: 160px;
    height: 48px;
    line-height: 48px;
   
    font-size: 15px;
  
  }
}
}
@media (min-width: 1000px) {
  .news-detail.w1200,.news-detail .w1200{
    padding: 0px 200px;
  }
}
</style>