<template>
  <div>
    <div class="service-wrap">
    </div>
    <div class="service w1200">
      <div class="service-title">{{ $t("message.msg36") }}</div>
      <img src="../assets/img_downLine.png" alt />
      <div class="service-content">
        {{ $t("message.msg37") }}
        <br />
        <br />At Expeditors, we know firsthand the complexities and time-sensitive nature of the automotive manufacturing world. A thorough understanding of the stakes and risks serve as the basis for how we engage with our customers. With a customer base that includes both original equipment manufacturers (OEMs) and tier suppliers, our comprehensive solutions employ all modes of transport to keep production and supply chains running smoothly. At the heart of our solutions for automotive customers is our network of global systems to manage and track logistics activity at a part or vehicle identification number (VIN) level. This superior visibility gives you the power to make timely and informed business decisions that keep your business at peak performance. Expeditors’ automotive services include: Global customs brokerage capability and expertise
      </div>
      <div class="service-tip">{{ $t("message.msg38") }}</div>
      <div class="service-btn btn-more">
        {{ $t("message.msg39") }}
        <img src="../assets/Icon_arrow_right.png" alt srcset />
      </div>
    </div>
    <div class="sections3 w1200" style="padding:40px 0px;">
            <Row>
        <Col :xs="24" :sm="24" :md="6">
          <div class="section3" @click="news">
            <div class="section3-tit">{{ $t("message.msg24") }}</div>
            <div class="section3-des">{{ $t("message.msg25") }}</div>
            <span class="section-more">{{ $t("message.more") }}</span>
          </div>
        </Col>
        <Col :xs="24" :sm="24" :md="6">
          <div class="section3" @click="casestudy">
            <div class="section3-tit">{{ $t("message.msg26") }}</div>
            <div class="section3-des">{{ $t("message.msg27") }}</div>
            <span class="section-more">{{ $t("message.more") }}</span>
          </div>
        </Col>
        <Col :xs="24" :sm="24" :md="6">
          <div class="section3" @click="serviceDetail">
            <div class="section3-tit">{{ $t("message.msg28") }}</div>
            <div class="section3-des">{{ $t("message.msg29") }}</div>
            <span class="section-more">{{ $t("message.more") }}</span>
          </div>
        </Col>
        <Col :xs="24" :sm="24" :md="6">
          <div class="section3" style="border-right:none" @click="tabledownload">
            <div class="section3-tit">{{ $t("message.msg30") }}</div>
            <div class="section3-des">{{ $t("message.msg31") }}</div>
            <span class="section-more">{{ $t("message.more") }}</span>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  mounted() {},
  data() {
    return {
      chooseTab: 0,
      chooseWay: "",
      sections: []
    };
  },
  mounted() {
    this.getSlide();
    this.getSections();
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == "zh") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "zh";
      }
    },
    changeTab(index) {
      this.chooseTab = index;
    },
    //幻灯片
    getSlide() {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/main?lang=${this.$i18n.locale}`)
        .then(function(res) {
          self.slides = res.data.data;
        });
    },
    //文章
    getSections() {
      let self = this;
      this.$axios
        .get(
          `/api/portal/articles?page=1&listRows=10&relation=categories&category_id=1&lang=${this.$i18n.locale}`
        )
        .then(function(res) {
          self.sections = res.data.data;
        });
    },
    serviceDetail(){
      this.$router.push('/servicedetail')
    }
    ,
    news(){
      this.$router.push('/news')
    },
    casestudy(){
      this.$router.push('/casestudy')
    },
    tabledownload(){
      this.$router.push('/tabledownload')
    }
  },
  watch: {
    "$i18n.locale"() {
      this.getSlide();
      this.getSections();
    }
  }
};
</script>
<style lang="scss" scoped>
.service-wrap {
  width: 100%;
  min-height: 500px;
  overflow: hidden;
  position: relative;
  .banner {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

.sections3 {
  padding: 88px 0px;
  .section3 {
    padding: 0px 18px;
    border-right: 1px solid rgba(1, 44, 86, 0.16);
    .section3-tit {
      margin-top: 18px;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 35, 60, 0.48);
      line-height: 20px;
    }
    .section3-des {
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 35, 60, 1);
      line-height: 33px;
      margin-bottom: 16px;
      height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .section-more {
      margin-left: 0px;
    }
  }
}
.service {
  padding: 24px 0px;
  .service-title {
    font-size: 44px;
    font-weight: 600;
    color: rgba(0, 35, 60, 1);
    line-height: 78px;
    margin-bottom: 24px;
  }
  .service-content {
    padding: 32px 0px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 35, 60, 1);
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 35, 60, 0.48);
  }
  .service-tip {
    font-size: 16px;
    font-weight: 400;
  }
  .service-btn {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .service-wrap{
    min-height: auto;
    .banner{
position: static;
transform: none;
width: 100%;
    }
  }
  .service {
    border-bottom: 1px solid rgba(1, 44, 86, 0.16);
    .service-title {
      font-size: 30px;
      padding-left: 16px;
      margin-bottom: 0px;
      line-height: 42px;
    }
    img{
      margin-left: 16px;
    }
    .service-content{
      margin: 0px;
      padding: 16px;
    }
    .service-tip{
      padding: 16px;
    }
    .service-btn{
      margin-left: 16px;
    }
  }
  .sections3 {
    padding: 0px !important;
    .section3 {
      padding-bottom: 16px;
      border-right: none;
      border-bottom: 1px solid rgba(1, 44, 86, 0.16);
      .section3-des {
        margin-bottom: 0px;
        min-height: 80px;
        height: auto;
        font-size: 20px;
      }
    }
  }
}
</style>