<template>
  <div style="background:rgba(0,35,60,0.05);">
    <div class="news-wrap">
      <div class="news-title">{{title || $t("message.msg24")}}</div>
      <img
        class="banner"
        v-if="categories && categories.more && categories.more.thumbnail"
        :src="categories && categories.more &&categories.more.thumbnail"
        srcset
      />
    </div>
    <div class="sections w1200">
      <Row>
        <Col :xs="24" :md="8" v-for="item in sections" :key="item.id">
          <div class="section-wrap" @click="goNewsDetail(item.id)">
            <div class="section">
              <img :src="item.thumbnail" />
              <div class="section-time">{{item.published_time.substr(0,10)}}</div>
              <div class="section-title">{{item.post_title}}</div>
              <div class="section-des">{{item.post_keywords}}</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div style="text-align:center" v-if="sections.length >=6 && !isTotal">
      <div
        class="btn-more"
        @click="getSections"
      >{{isLoading ? $t("message.msg40") : $t("message.msg41")}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  components: {},
  mounted() {},
  data() {
    return {
      isLoading: false,
      isTotal:false,
      sections: [],
      page: 1,
      title: "",
      categories: ""
    };
  },
  mounted() {
    this.title = this.$route.query.name || "";
    this.getSections();
    this.getCategory();
  },
  methods: {
    //分类图片
    getCategory() {
      let self = this;
      let id = this.$route.query.id || (this.$i18n.locale == "en" ? 26 : 6);
      this.$axios
        .get(
          `/api/portal/categories/${id}&lang=${this.$i18n.locale}&relation=categories`
        )
        .then(function(res) {
          console.log(res);
          self.categories = res.data.data;
        });
    },
    //文章
    getSections() {
      let self = this;
      this.isLoading = true;
      let id = this.$route.query.id || (this.$i18n.locale == "en" ? 26 : 6);

      this.$axios
        .get(
          `/api/portal/lists/getCategoryPostLists?category_id=${id}&page=${this.page}&listRows=60&lang=${this.$i18n.locale}&relation=categories`
        )
        .then(function(res) {
          console.log(res);
          self.isLoading = false;
          if (res.data.data.list.length > 0) {
            self.sections = self.sections.concat(res.data.data.list);
              self.title = res.data.data.list[0].categories[0].name;
            } else {
              this.isTotal = true;
            }
            self.page += 1;
        });
    },
    //文章详情
    goNewsDetail(id) {
      this.$router.push("/newsdetail?id=" + id);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.page = 1;
      this.sections = [];
      this.getSections();
    },
    "$route.query.id"() {
      this.page = 1;
      this.sections = [];
      this.getSections();
    },
    "$route.query.name"() {
      this.title = this.$route.query.name;
    }
  }
};
</script>
<style lang="scss" scoped>
.news-wrap {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  margin-bottom: 36px;
  .news-title {
    font-size: 44px;
    font-weight: 500;
    color: rgba(0, 35, 60, 1);
    line-height: 78px;
    margin-top: 90px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 9;
  }
  .banner {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    // height: 300px;
    width: 100%;
  }
}
.section-wrap {
  overflow: hidden;
}

.section {
  cursor: pointer;
  box-sizing: border-box;
  // max-width: 376px;
  flex: 1 1 300px;
  padding-bottom: 30px;
  margin-bottom: 36px;
  margin-right: 36px;
  background: #fff;
  img {
    width: 100%;
    height: 180px;
  }
  .section-time {
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    color: rgba(0, 35, 60, 0.48);

    margin: 16px 16px 0px 16px;
  }
  .section-title {
    margin: 0px 16px;
    line-height: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 35, 60, 1);
    text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
  }
  .section-des {
    font-size: 16px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 16px;
    font-weight: 400;
    color: rgba(0, 35, 60, 0.48);
    line-height: 24px;
    margin-bottom: 4px;
    text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.section-more {
  margin-left: 16px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(67, 176, 212, 1);
  line-height: 22px;
  text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
  text-decoration: underline;
  cursor: pointer;
}
.btn-more {
  margin-bottom: 36px;
}
@media (max-width: 768px) {
  .news-wrap .news-title {
    font-size: 30px;
  }
  .sections {
    padding-bottom: 30px;
  }
  .section {
    padding-bottom: 10px;
    margin: 4px 2px;
    .section-time {
      margin: 0px 16px;
      font-size: 16px;
    }
    .section-title {
      font-size: 20px;
      margin: 12px 16px 4px 16px;
    }
  }
}
</style>