<template>
  <div>
    <div class="news-wrap">
      <div class="news-title">{{ $t("message.msg26") }}</div>
    </div>
    <div class="sections w1200">
      <Row>
        <Col :xs="12" :md="8" v-for="item in lists" :key="item.id">
          <div class="section-wrap" @click="goNewsDetail(item.id)">
            <div class="section">
              <img :src="item.thumbnail" alt />
              <div class="section-time">{{item.published_time.substr(0,10)}}</div>
              <div class="section-title">{{item.post_title}}</div>
              <div class="section-des">{{item.post_keywords}}</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div style="text-align:center">
      <div class="btn-more" @click="getlists">{{isLoading ? $t("message.msg40") : $t("message.msg41")}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  components: {},
  mounted() {},
  data() {
    return {
      isLoading:false,
      lists: [],
      page:1,
    };
  },
  mounted() {
    this.getlists();
  },
  methods: {
      //文章
    getlists() {
    let self = this;
      this.isLoading = true
      let id = this.$route.query.id
      this.$axios
        .get(
          `/api/portal/lists/getCategoryPostLists?category_id=${this.$i18n.locale == 'cn' ? 18 : 21}&page=${this.page}&lang=${this.$i18n.locale}`
        )
        .then(function(res) {
          console.log(res);
          if(res.data.data.list.length > 0){
 self.lists =self.lists.concat(res.data.data.list);
 self.page +=1
          }
         
          self.isLoading = false
        });
    },
    //文章详情
    goNewsDetail(id){
      this.$router.push('/newsdetail?id=' + id)
    }
  },
  watch: {
    "$i18n.locale"() {
      this.lists =[]
      this.page = 1
      this.getlists();
    }
  }
};
</script>
<style lang="scss" scoped>
.news-wrap {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  margin-bottom: 36px;
  .news-title {
    font-size: 44px;
    font-weight: 500;
    color: #fff;
    line-height: 78px;
    margin-top: 90px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 9;
  }
  .banner {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}
.section-wrap {
  overflow: hidden;
}

.section {
  box-sizing: border-box;
  // max-width: 376px;
  flex: 1 1 300px;
  padding-bottom: 30px;
  margin-bottom: 36px;
  margin-right: 36px;
  background: #fff;
  img {
    width: 100%;
    height: 180px;
  }
  .section-time {
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    color: rgba(0, 35, 60, 0.48);
    margin: 16px;
  }
  .section-title {
    margin: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 35, 60, 1);
    text-align: left;
    text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
  }
  .section-des {
    font-size: 16px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 16px;
    font-weight: 400;
    color: rgba(0, 35, 60, 0.48);
    line-height: 24px;
    margin-bottom: 4px;
    text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
  }
}
.section-more {
  margin-left: 16px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(67, 176, 212, 1);
  line-height: 22px;
  text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
  text-decoration: underline;
  cursor: pointer;
}
.btn-more {
  margin-bottom: 36px;
}
@media (max-width: 768px) {
  .news-wrap .news-title{
    font-size: 30px;
  }
  .sections{
    padding-bottom: 30px;
  }
  .section{
    margin: 4px 2px;
    .section-time{
      margin: 0px 16px;
      font-size: 16px;
    }
    .section-title{
      font-size: 20px;
      margin: 0px 16px;
    }
  }
  
}
</style>